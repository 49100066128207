export default Object.freeze({
  GENERAL__DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  GENERAL__FAQ_PER_PAGE: 3,
  GENERAL__RECORDS_PER_PAGE: 30,
  GENERAL__INPUT_TYPES: {
    GARENA_ACCOUNT: 'garena_account',
    EMAIL: 'email',
    PHONE: 'phone',
  },
  GENERAL__MAX_FILE_CNT: {
    DEFAULT: 4,
    APPLICATION: 3,
    TICKET: 4,
  },
  GENERAL__POPUP_TYPES: {
    ALERT: 'alert',
    CONFIRM: 'confirm',
  },
  GENERAL__GOP_PLATFORM: {
    GARENA: 1,
    FACEBOOK: 3,
    VK: 5,
    LINE: 6,
    HUAWEI: 7,
    GOOGLE: 8,
    WECHAT: 9,
    APPLE: 10,
    TWITTER: 11,
  },
  GENERAL__ACCOUNT_CENTER_URL: 'https://account.garena.com/',
  SEARCH__SEARCH_MENU_LISTS: { PC: 'PC', MOBILE: 'MOBILE', OTHERS: 'OTHERS' },
  SEARCH__SEARCH_MENU_ITEMS_COUNT_PER_LIST: 3,
  SEARCH__SEARCH_TYPES: { PC: 'game', MOBILE: 'game', OTHERS: 'category' },
  PROFILE__GENDER_TYPES: { MALE: 1, FEMALE: 2 },
  TICKET__TEXTAREA_MAXLENGTH: 10000,
  TICKET__TICKET_REPLY__CREATOR_TYPES: {
    USER: 1,
    STAFF: 2,
  },
  TICKET__TICKET_REPLY_TYPES: {
    TICKET_CONTENT: 0,
    STAFF: 1,
    USER: 2,
    SYSTEM: 3,
  },
  TICKET__TICKET_STATUS_FOR_USER: {
    ALL: 0,
    PENDING: 1,
    PROCESSING: 2,
    CLOSED: 3,
    LEGACY: 4,
  },
  TICKET__TICKET_STATUS_FILTER: {
    ALL: 'ALL',
    PENDING: 'PENDING',
    PROCESSING: 'PROCESSING',
    CLOSED: 'CLOSED',
  },
  TICKET__TICKET_STATUS_MAPPING: {
    0: { FILTER_STATUS: 'PROCESSING', TICKET_STATUS: 'PENDING_USER_REPLY' },
    1: { FILTER_STATUS: 'PROCESSING', TICKET_STATUS: 'STAFF_REPLIED' },
    2: { FILTER_STATUS: 'PROCESSING', TICKET_STATUS: 'PROCESSING' },
    3: { FILTER_STATUS: 'PROCESSING', TICKET_STATUS: 'SUBMITTED' },
    4: { FILTER_STATUS: 'PROCESSING', TICKET_STATUS: 'USER_REPLIED' },
    5: { FILTER_STATUS: 'PENDING', TICKET_STATUS: 'PENDING' },
    6: { FILTER_STATUS: 'CLOSED', TICKET_STATUS: 'SOLVED' },
    7: { FILTER_STATUS: 'CLOSED', TICKET_STATUS: 'REPLIED' },
    8: { FILTER_STATUS: 'CLOSED', TICKET_STATUS: 'SYSTEM_CLOSED' },
    9: { FILTER_STATUS: 'CLOSED', TICKET_STATUS: 'DUPLICATED' },

    // not used for frontend now
    10: { FILTER_STATUS: 'PROCESSING', TICKET_STATUS: 'NO_RESPONSE' },

    11: { FILTER_STATUS: 'LEGACY', TICKET_STATUS: 'PENDING_USER_REPLY' },
    12: { FILTER_STATUS: 'LEGACY', TICKET_STATUS: 'STAFF_REPLIED' },
    13: { FILTER_STATUS: 'LEGACY', TICKET_STATUS: 'PROCESSING' },
    14: { FILTER_STATUS: 'LEGACY', TICKET_STATUS: 'SUBMITTED' },
    15: { FILTER_STATUS: 'LEGACY', TICKET_STATUS: 'USER_REPLIED' },
    16: { FILTER_STATUS: 'LEGACY', TICKET_STATUS: 'PENDING' },
    17: { FILTER_STATUS: 'LEGACY', TICKET_STATUS: 'SOLVED' },
    18: { FILTER_STATUS: 'LEGACY', TICKET_STATUS: 'REPLIED' },
    19: { FILTER_STATUS: 'LEGACY', TICKET_STATUS: 'SYSTEM_CLOSED' },
    20: { FILTER_STATUS: 'LEGACY', TICKET_STATUS: 'DUPLICATED' },
  },
  TICKET__TICKET_REPLY_RATE: {
    IS_HELPFUL: true,
    IS_NOT_HELPFUL: false,
  },
  TICKET__TICKET_RATE: {
    IS_SATISFIED: 1,
    IS_NOT_SATISFIED: 0,
  },
  APPLICATION__TYPES: {
    VERIFICATION: 0,
    CANT_LOGIN: 1,
    OTHERS: 2,
  },
  APPLICATION__VERIFICATION_TYPES: {
    RESET_PASSWORD: 1,
    CANCEL_EMAIL: 2,
    CANCEL_PHONE: 3,
  },
  APPLICATION__VERIFICATION_NOTIFICATION_TYPES: {
    EMAIL: 0,
    SMS: 1,
  },
  APPLICATION__VERIFICATION_RESULTS: {
    SUCCESS: 'success',
    FAILURE: 'failure',
  },
  APPLICATION__VERIFICATION_STATUS_MAPPING: {
    1: 'OK',
    2: 'OK',
    3: 'NG',
    4: 'NG',
    5: 'NG',
    6: 'OK',
    7: 'NG',
    8: 'NG',
    9: 'NG',
    10: 'NG',
    11: 'NG',
    12: 'OK',
    13: 'NG',
    14: 'NG',
  },
  APPLICATION__VERIFICATION_STATUS: {
    PENDING: 1,
    OK_PROCESSING: 2,
    NG_BLURRED_ID: 3,
    NG_NO_COLOR_ID: 4,
    NG_MISMATCHED_ID: 5,
    CANCELLED: 6,
    NG_INCOMPLETE_ID: 7,
    NG_INVALID_ID: 8,
    NG_MISMATCHED_SERVICE_REGION: 9,
    NG_CONTACT_SUPPORT: 10,
    NG_OVER_LIMIT: 11,
    COMPLETED: 12,
    DUPLICATED: 13,
    NG_ALREADY_VERIFIED: 14,
  },
  APPLICATION__ACCOUNT_RECOVERY_USER_INFO_STATUS: {
    COMPLETE: 1,
    NOT_COMPLETE: 0,
  },
  APPLICATION__ACCOUNT_RECOVERY_USER_TOPUP_STATUS: {
    YES: 1,
    NO: 0,
  },
  APPLICATION__ACCOUNT_RECOVERY_STATUS: {
    PENDING: 1,
    OK_PROCESSING: 2,
    NG_BLURRED_ID: 3,
    NG_NO_COLOR_ID: 4,
    NG_MISMATCHED_ID: 5,
    CANCELLED: 6,
    NG_INCOMPLETE_ID: 7,
    NG_ID_NOT_FOUND: 8,
    NG_MISMATCHED_PLAYER_INFO: 9,
    NG_INCOMPLETE_USER_INFO: 10,
    NG_INCOMPLETE_PLAYER_INFO: 11,
    NG_INVALID_ID: 12,
    NG_OVER_LIMIT: 13,
    NG_MISMATCHED_SERVICE_REGION: 14,
    NG_CONTACT_SUPPORT: 15,
    DUPLICATED: 16,
    NG_ALREADY_VERIFIED: 17,
    COMPLETED: 18,
  },
  APPLICATION__ACCOUNT_RECOVERY_STATUS_MAPPING: {
    1: 'OK',
    2: 'OK',
    3: 'NG',
    4: 'NG',
    5: 'NG',
    6: 'OK',
    7: 'NG',
    8: 'NG',
    9: 'NG',
    10: 'NG',
    11: 'NG',
    12: 'NG',
    13: 'NG',
    14: 'NG',
    15: 'NG',
    16: 'OK',
    17: 'NG',
    18: 'OK',
  },
  AI_FAQ__CHAT_IMG: {
    aov: 'https://support.cdn.garenanow.com/support-center/tw/games/aov/ai-icon-aov.jpg',
    ff: 'https://support.cdn.garenanow.com/support-center/tw/games/ff/ai-icon-ff.png',
  },
  AI_FAQ__CHAT_ROLE: {
    USER: 'user',
    ASSISTANT: 'assistant',
    LOADING: 'loading',
    SYSTEM: 'system',
  },
  AI_FAQ__ASSISTANT_NAME: {
    aov: '小幫手克里希',
    ff: '小幫手莫科',
  },
  MYINFO_STORAGE_KEY: '_myinfo',
});
