<template>
  <div class="main-wrap">
    <div class="main-bg"></div>
    <div class="main">
      <navigation :title="$t('GENERAL__TITLE')" :has-search-bar="true" />
      <section class="game-section">
        <div class="cproblem__main-content">
          <div class="faqdetail__content">
            <div class="ip-solution__txt">
              {{ $t('MYINFO_BACKFORM') }}
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import common from '@/utils/common';
import constants from '@/lib/constants';
import Navigation from '@/components/mobile/layouts/Navigation';

export default {
  name: 'Storage',
  components: {
    Navigation,
  },
  beforeMount() {
    const { search } = window.location;
    const data = common.parseQuery(decodeURIComponent(search));
    const { skey } = data;
    if (skey) {
      window.localStorage.setItem(constants.MYINFO_STORAGE_KEY, search);
    }
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .main {
    height: 100%;
  }
  .game-section {
    display: flex;
    flex-flow: column;
    padding-top: 40px;
  }
  .ip-solution__txt {
    margin: 12px 12px 20px 12px;
    text-align: center;
  }
}
</style>
