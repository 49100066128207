<template>
  <div class="main-wrap main-wrap--hassearch">
    <div class="main-bg" />
    <div class="main">
      <navigation :title="$t('GENERAL__TITLE')" :has-search-bar="true" />
      <div class="main-inner">
        <div class="faqdetail-main">
          {{ $t('MYINFO_BACKFORM') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from '@/utils/common';
import constants from '@/lib/constants';
import Navigation from '@/components/pc/layouts/Navigation';

export default {
  name: 'Storage',
  components: {
    Navigation,
  },
  beforeMount() {
    const { search } = window.location;
    const data = common.parseQuery(decodeURIComponent(search));
    const { skey } = data;
    if (skey) {
      window.localStorage.setItem(constants.MYINFO_STORAGE_KEY, search);
    }
  },
};
</script>

<style lang="scss" scoped>
.pc {
  .faqdetail-main {
    height: 180px;
    text-align: center;
  }
}
</style>
