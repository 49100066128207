var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-wrap" }, [
    _c("div", { staticClass: "main-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", {
          attrs: { title: _vm.$t("GENERAL__TITLE"), "has-search-bar": true },
        }),
        _vm._v(" "),
        _c("section", { staticClass: "game-section" }, [
          _c("div", { staticClass: "cproblem__main-content" }, [
            _c("div", { staticClass: "faqdetail__content" }, [
              _c("div", { staticClass: "ip-solution__txt" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("MYINFO_BACKFORM")) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }